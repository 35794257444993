import { current, createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { apiUrl } from '../../configs';
import { authFetch } from '../../utils';

export const getAllEvents = createAsyncThunk(
  'events/getAll',
  async (params: any, thunkAPI) => {
    const { tokens, timelineId } = params;
  
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/${timelineId}/events`,
    }, thunkAPI)
    
    const { status } = result;
    const body = await result.json();
    if (status === 200) {
      return thunkAPI.fulfillWithValue(body);
    }
    return thunkAPI.rejectWithValue(body);
  }
);

export const getAllEventsNext = createAsyncThunk(
  'events/getAllNext',
  async (params: any, thunkAPI) => {
    const { tokens, timelineId, nextKey } = params;
    const qparams = `?nextkey=${nextKey}`;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/${timelineId}/events${nextKey ? qparams : ''}`,
    }, thunkAPI)
    console.log(nextKey);
    const { status } = result;
    const body = await result.json();
    if (status === 200) {
      return thunkAPI.fulfillWithValue(body);
    }
    return thunkAPI.rejectWithValue(body);
  }
);

export const createEvent = createAsyncThunk(
  'events/create',
  async (params: any, thunkAPI) => {
    const { newPhoto, tokens, title, description, timelineId, datetime } = params;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/${timelineId}/events`,
      param: {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title, description, hasPhoto: !!newPhoto, datetime, timelineId,
        }),
      },
    }, thunkAPI);
    const { status } = result;
    const body = await result.json();
    if (status !== 201) {
      return thunkAPI.rejectWithValue(body);  
    }
    if (newPhoto) { 
      const { uploadUrl } = body;  
      const uploadResult = await fetch(uploadUrl, {
        method: 'PUT',
          headers: {
            'Content-Type': 'image/jpg'
          },
          body: await fetch(newPhoto).then(r => r.blob()),
      });
      console.log('uploadresult', uploadResult);
    }
    thunkAPI.dispatch(getAllEvents({ tokens, timelineId }));
    return thunkAPI.fulfillWithValue(body);
  }
)

export const updateEventById = createAsyncThunk(
  'events/updateEventById',
  async (params: any, thunkAPI) => {
    const { newPhoto, tokens, url, title, description, id, timelineId, datetime } = params;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/${timelineId}/events/${id}`,
      param: {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title, description, url, newPhoto, datetime,
        }),
      },
    }, thunkAPI);
    const { status } = result;
    const body = await result.json();
    if (status === 200) {
      if (newPhoto) {
        const { uploadUrl } = body;
        const uploadResult = await fetch(uploadUrl, {
          method: 'PUT',
            headers: {
              'Content-Type': 'image/jpg'
            },
            body: await fetch(newPhoto).then(r => r.blob()),
        });
        console.log(uploadResult);
      }
      thunkAPI.dispatch(getAllEvents({ tokens, timelineId }));
      return thunkAPI.fulfillWithValue(body);
    }
    return thunkAPI.rejectWithValue(body);
  }
)

export const deleteTimelineById = createAsyncThunk(
  'events/deleteById',
  async (params: any, thunkAPI) => {
    const { tokens, id, timelineId } = params;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/${timelineId}/events/${id}`,
      param: {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    }, thunkAPI);
    const { status } = result;
    const body = await result.json();
    if (status === 200) {
      thunkAPI.dispatch(getAllEvents({ tokens, timelineId }));
      return thunkAPI.fulfillWithValue(id);
    }
    return thunkAPI.rejectWithValue(body);
  }
)

interface EventsState {
  events: any;
  nextKey: any,
  selectedId: string;
  loading: boolean;
  apiError: any;
};

const initialState = { events: {} } as EventsState

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    reset(state) {
      state.events = {};
    },
    decrement(state) {
      state.loading = false;
    },
    incrementByAmount(state, action: PayloadAction) {
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEventsNext.pending, (state, action) => {
          state.loading = true;
          state.apiError = '';
      })
      .addCase(getAllEventsNext.fulfilled, (state, action: any) => {
        state.loading = false;
        
        const data = JSON.parse(JSON.stringify(state.events[action.payload.data[0].timelineId].data));
        
        if (action.payload.data.length > 0) {
          const newData = data.concat(action.payload.data);
          action.payload.data = newData;
          state.events[action.payload.data[0].timelineId] = action.payload;
        }
      })
      .addCase(getAllEventsNext.rejected, (state, action) => {
        state.loading = false;
        state.apiError = action.payload;
      })
      .addCase(getAllEvents.pending, (state, action) => {
         state.loading = true;
         state.apiError = '';
      })
      .addCase(getAllEvents.fulfilled, (state, action: any) => {
        state.loading = false;
        if (action.payload.data.length > 0) {
          state.events[action.payload.data[0].timelineId] = action.payload;
        }
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.loading = false;
        state.apiError = action.payload;
      })
      .addCase(createEvent.pending, (state, action) => {
          state.loading = true;
          state.apiError = '';
      })
      .addCase(createEvent.fulfilled, (state, action: any) => {
        state.loading = false;
        // const prevEvents = JSON.parse(JSON.stringify(state.events));
        // success
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.loading = false;
        state.apiError = action.payload;
      })
  },
})

export const { reset, decrement, incrementByAmount } = eventsSlice.actions
export default eventsSlice.reducer