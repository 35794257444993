import React from 'react';
import { IonButton, IonCardContent, IonItem, IonLabel, IonImg } from '@ionic/react';
// import '../Page.css';

interface Event {
  id: string;
  title?: string;
  description?: string;
  datetime?: any;
  timelineId?: string;
  updatedOn?: number;
  url?: string;
}

interface MyProps {
  event?: Event;
  handleEdit: (id: string) => void;
};

const EventItem: React.FC<MyProps> = ({ event, handleEdit }) => {
  if (!event) {
    return (<></>);
  }
  const thisDate = new Date(event.datetime);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const h = thisDate.getHours();
  const hour = h < 10 ? `0${h}` : h;
  const m = thisDate.getMinutes();
  const min = m < 10 ? `0${m}` : m;
  return (
    <li>
      <div className="event-card">
        <IonItem>
          <IonLabel>{event.title}</IonLabel>
          <IonButton fill="outline" slot="end" onClick={() => handleEdit(event.id)}>View</IonButton>
        </IonItem>
        <IonCardContent>
          <div className="description">
            {event.description}
          </div>
        </IonCardContent>
        <IonImg src={event.url} />
        <span className="date-time">
          <div className="date">{thisDate.getDate()}</div>
          <div className="month">{monthNames[thisDate.getMonth()]}</div>
          <div className="year">{thisDate.getFullYear()}</div>
          <div className="time">{`${hour}:${min}`}</div>
        </span>
      </div>
    </li>
  );
};

export default EventItem;
