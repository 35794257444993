import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { IonButtons, IonIcon, IonContent, IonFab, IonFabButton, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, IonCard, IonCardContent, IonItem, IonLabel, IonImg, IonModal, IonActionSheet, IonToast } from '@ionic/react';
import {  add } from 'ionicons/icons';
import TimelineModal from '../components/TimelineModal';
import './Page.css';
import { getAllTimelines, resetApiError } from '../store/timelines/slice';
import { RootState } from '../store';

type MyProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  getAllTimelines: any;
};
type MyState = {
  timelines: any;
  modalOpen: boolean;
  photo: string;
  showToast: boolean;
};

class TimelinesPage extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      timelines: [],
      modalOpen: false,
      photo: '',
      showToast: false,
    };
  }
  componentDidMount() {
    this.props.getAllTimelines({ tokens: this.props.tokens });
  }
  
  
  addTimeline = () => {
    this.setState(state => ({
      modalOpen: true,
    }));
  }
  closeModal = () => {
    this.setState(state => ({
      modalOpen: false,
      photo: '',
    }));
  }
  closeToast = () => {
    this.setState({ showToast: false });
    this.props.resetApiError();
  }
  render() {
    return (
      <IonPage>
        <IonToast
        isOpen={!!this.props.apiError}
        onDidDismiss={this.closeToast}
        duration={5000}
        message={this.props.apiError}
        position="bottom"
      />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Your Timelines</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonModal isOpen={this.state.modalOpen} >
          <TimelineModal
            handleCancel={this.closeModal}
            photo={this.state.photo}
            />
        </IonModal>
        <IonContent fullscreen>
          <IonFab color="warning" slot="fixed" horizontal="end" vertical="bottom" >
            <IonFabButton onClick={this.addTimeline}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
          {
            this.props.timelines.length === 0 && (
              <IonCardContent>
                <p>You have no timelines.</p>
                <IonButton onClick={this.addTimeline}>Click here to add new timeline.</IonButton>
              </IonCardContent>
            )
          }
          {
            this.props.timelines.map((item: any, index: number) => {
              return (
                <IonCard key={index}>
                  <IonImg src={item.url} />
                  <IonItem>
                    <IonLabel>{item.title}</IonLabel>
                    <IonButton fill="outline" slot="end" routerLink={`/timelines/${item.id}`}>View</IonButton>
                    {/* <IonButton fill="outline" slot="end">Share</IonButton>     */}
                  </IonItem>
                  <IonCardContent>
                    <div className="description">
                      {item.description}
                    </div>
                  </IonCardContent>
                </IonCard>
              );              
            })
          }          
        </IonContent>
      </IonPage>
    );
  }
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.user.isLoggedIn,
  tokens: state.user.tokens,
  timelines: state.timelines.timelines,
  apiError: state.timelines.apiError,
});

const mapDispatchToProps = {
  getAllTimelines,
  resetApiError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimelinesPage);
