import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import userSlice from './user/slice';
import * as userActions from './user/slice';
import timelinesSlice from './timelines/slice';
import eventsSlice from './events/slice';

function saveToLocalStorage (state: any) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem('persistantState', serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem('persistantState');
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

export const store = configureStore({
  reducer: {
    user: userSlice,
    timelines: timelinesSlice,
    events: eventsSlice,
  },
  middleware: [logger, thunk],
  preloadedState: loadFromLocalStorage(),
});
store.subscribe(() => saveToLocalStorage(store.getState()));
export type RootState = {
  events: any;
  user: any;
  timelines: any;
};
export const actions = {
  user: userActions,
};