import { useIonAlert, IonButton, IonHeader, IonInput, IonTextarea, IonTitle, IonIcon, IonButtons, IonContent, IonToolbar, IonItem, IonLabel, IonLoading } from "@ionic/react";
import { useState } from 'react';
import { useDispatch } from "react-redux"
import { closeOutline, trashOutline } from 'ionicons/icons';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { updateEventById, createEvent, deleteTimelineById } from '../store/events/slice';
interface ContainerProps {
  handleCancel: () => void;
  photo?: string;
  pTitle?: string;
  pDescription?: string;
  pDatetime?: string;
  eventId?: string;
  timelineId: string;
  tokens: any;
}

const EventModal: React.FC<ContainerProps> = ({ eventId, pTitle, pDescription, pDatetime, handleCancel, photo, tokens, timelineId }) => {
  const [present] = useIonAlert();
  let dt = pDatetime;
  if (pDatetime) {
    const dtconverted = new Date(pDatetime).toISOString();
    dt = dtconverted.split(':')[2];
    dt = dtconverted.replace(`:${dt}`, '');
  }
  
  const [description, setDescription] = useState(pDescription);
  const dispatch = useDispatch();
  const [title, setTitle] = useState(pTitle);
  const [datetime, setDatetime] = useState(dt);
  const [image, setImage] = useState(photo);
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deleteTimelineById({
      tokens,
      timelineId,
      id: eventId,
    }));
    handleCancel();
    setLoading(false);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const eventId = e.target[0].value;
    const title = e.target[1].value;
    const description = e.target[2].value;
    const datetime = new Date(e.target[3].value).getTime();
    if (eventId) {
      await dispatch(updateEventById({
        id: eventId,
        timelineId,
        title,
        datetime,
        description,
        url: image,
        newPhoto: image,
        tokens,
      }));
    } else {
      await dispatch(createEvent({
        title,
        timelineId,
        datetime,
        description,
        url: image,
        newPhoto: image,
        tokens,
      }));
    }
    
    handleCancel();
    setLoading(false);
  };
  const takePicture = async () => {
    try {
      const image: any = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        correctOrientation: true,
        quality: 20,
        saveToGallery: true,
        allowEditing: false,
      });
      const imageUrl = image.webPath;
      setImage(imageUrl);
    } catch (e) {
      console.log(e);
    }
    
  };
  return (
    <IonContent className="container">
      <IonLoading
        isOpen={loading}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() =>
                present({
                  cssClass: 'my-css',
                  header: 'Delete',
                  message: 'Are you sure you want to delete this event?',
                  buttons: [
                    'Cancel',
                    {
                      text: 'Ok',
                      handler: handleDelete,
                    },
                  ],
                  onDidDismiss: (e) => console.log('did dismiss'),
                })
              }
            >
              <IonIcon icon={trashOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle slot="start">{ eventId ? 'Edit' : 'New'} Event</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleCancel}><IonIcon icon={closeOutline} /></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <form className="ion-padding" onSubmit={e => { handleSubmit(e) }}>
        <IonItem>
          <IonInput hidden value={eventId} />
          <IonLabel position="floating">Title</IonLabel>
          <IonInput required type="text" value={title} onIonChange={(e) => setTitle((e.target as HTMLInputElement)!.value)} />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Description</IonLabel>
          <IonTextarea required className="textarea" auto-grow={true} value={description} onIonChange={(e) => setDescription((e.target as HTMLInputElement)!.value)} />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Date & Time</IonLabel>
          <IonInput required type="datetime-local" value={datetime} onIonChange={(e) => setDatetime((e.target as HTMLInputElement)!.value)} />
        </IonItem>
        <img
          className="ion-float-left"
          alt=""
          src={image} />
          <br />
          <br />
        {image && (
          <>
            <IonButton className="ion-float-left" color="light" onClick={() => setImage('')}>Remove Photo</IonButton>
          </>
        )}
        {!image && (
          <>
            <IonButton className="ion-float-left" onClick={() => takePicture()}>Upload Photo</IonButton>
          </>
        )}
        <br/>
        <br/>
        <br/>
        <IonButton className="ion-margin-top ion-float-right ion-float-bottom" type="submit">{eventId ? 'Edit' : 'Add'}</IonButton>
        <IonButton className="ion-margin-top ion-float-right" type="reset" color="light" onClick={handleCancel}>Cancel</IonButton>
      </form>
    </IonContent>
  );
};

export default EventModal;
