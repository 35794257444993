import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2';

let appClientId = '1j28radm5vesvh0s360s0uddpk';
let baseUrl = 'https://devtimeline.klipa.app';
let apiUrl = 'https://devapi.timeline.klipa.app/api';
// apiUrl = 'https://se8mfj1jl8.execute-api.us-east-1.amazonaws.com/dev/api/';
let userPoolId = 'us-east-1_oXHIKbo6x';
let forgotPassword = '';
let tokenUrl = 'https://timeline.auth.us-east-1.amazoncognito.com/oauth2/token';
const env = process.env.ENV || 'prod';
if (env === 'prod') {
  baseUrl = 'https://timeline.klipa.app';
  appClientId = 'r25hnes0kmj8s3di8d70ndrtk';
  apiUrl = 'https://api.timeline.klipa.app/api';
  userPoolId = 'us-east-1_EysNKsvSo';
  forgotPassword = '';
  tokenUrl = 'https://auth.timeline.klipa.app/oauth2/token';;
}
const refreshParameterBody = `grant_type=refresh_token&client_id=${appClientId}`;
const IAPSubscriptions: Record<string, any>  = {
  'pt_sb_bz_01': {
    id: 'pt_sb_bz_01',
    type: InAppPurchase2.PAID_SUBSCRIPTION,
    title: 'Timeline Bronze',
    description: 'Subscribe to get an ad free experience',
    price: '$2.99',
  },
  'pt_sb_gld_0214': {
    id: 'pt_sb_gld_0214',
    type: InAppPurchase2.PAID_SUBSCRIPTION,
    title: 'Timeline Gold',
    description: 'Ad free experience and Gold features',
    price: '$4.99',
  },
  'pt_sb_pt_0516': {
    id: 'pt_sb_gld_0516',
    type: InAppPurchase2.PAID_SUBSCRIPTION,
    title: 'Timeline Platinum',
    description: 'Ad free experience and Platinum features',
    price: '$6.99',
  },
};
const subscriptionsArray = [
  {
    id: 'pt_sb_bz_01',
    type: InAppPurchase2.PAID_SUBSCRIPTION,
  },
  {
    id: 'pt_sb_gld_0214',
    type: InAppPurchase2.PAID_SUBSCRIPTION,
  },
  {
    id: 'pt_sb_pt_0516',
    type: InAppPurchase2.PAID_SUBSCRIPTION,
  },
];


export {
  IAPSubscriptions,
  subscriptionsArray,
  tokenUrl,
  refreshParameterBody,
  baseUrl,
  appClientId,
  apiUrl,
  userPoolId,
  forgotPassword,
};