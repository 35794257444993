import React, { useEffect, useState } from 'react';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2';
import { useSelector, useDispatch } from "react-redux"
import {
  IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
  IonButtons, IonMenuButton, IonRow, IonCol, IonButton,
  withIonLifeCycle, isPlatform,
  IonList, IonItem, IonLabel, IonInput, IonText, IonLoading,IonToast, IonChip, IonItemGroup, IonItemDivider, IonCard, IonCardContent
} from '@ionic/react';
import { RootState } from '../store';
import { IAPSubscriptions, subscriptionsArray } from '../configs';

const SubscriptionsPage: React.FC = () => {
  const username = useSelector((state: RootState) => state.user.tokens.idToken.payload['cognito:username']);
  const tokenPayload = useSelector((state: RootState) => state.user.tokens.idToken.payload);
  const [loading, setLoading] = useState(false);
  const [sub1, setSub1] = useState('{}');
  const [sub2, setSub2] = useState('{}');
  const [successMessage, setSuccessMessage] = useState('');
  const [userSubscription, setUserSubscription] = useState('');
  const [subscription, setSubscription] = useState('');
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [apiError, setApiError] = useState('');

  const buySubscription = async (e: React.FormEvent) => {
    
  };

  useEffect(() => {
    if (tokenPayload) {
      const userSubscription: string = tokenPayload['custom:subscription'];
      if (!userSubscription) {
        if (['PM-SB-01', 'pm_sb_01'].indexOf(userSubscription) >= 0) {
          setUserSubscription(IAPSubscriptions.pm_sb_bz_01.title);
        } else if (userSubscription) {
          if (IAPSubscriptions[userSubscription]) {
            setSubscription(IAPSubscriptions[userSubscription].id);
            setUserSubscription(IAPSubscriptions[userSubscription].title);
          } else {
            setUserSubscription('No Subscription');
          }
        }
      }
    }
    if (isPlatform('desktop')) {
      // setSubscriptionList(IAPSubscriptions);
      setSub1(JSON.stringify(IAPSubscriptions[Object.keys(IAPSubscriptions)[0]]));
      setSub2(JSON.stringify(IAPSubscriptions[Object.keys(IAPSubscriptions)[2]]));
      return;
    }
    registerOnce();    
  }, []);

  const registerOnce = () => {
    getProduct1(Object.keys(IAPSubscriptions)[0]);
    // getProduct(Object.keys(IAPSubscriptions)[1]);
    getProduct3(Object.keys(IAPSubscriptions)[2]);
    registerHandlers(Object.keys(IAPSubscriptions)[0]);
    // registerHandlers(Object.keys(IAPSubscriptions)[1]);
    registerHandlers(Object.keys(IAPSubscriptions)[2]);
    InAppPurchase2.register(subscriptionsArray);
  }

  const getProduct1 = async (productId: string) => {
    const item = InAppPurchase2.get(productId);
    setSub1(JSON.stringify(item));
  }
  const getProduct3 = async (productId: string) => {
    const item = InAppPurchase2.get(productId);
    setSub2(JSON.stringify(item));
  }

  const registerHandlers = (productId: string) => {
    // InAppPurchase2.when(productId).updated((product: any) => {
    //   if (!product.title) {
    //     return;
    //   }
    // });
    // InAppPurchase2.when(productId).owned(async (product: IAPProduct) => {
    //   // set admob to nothing
    //   if (product.loaded && product.valid && (product.state === InAppPurchase2.OWNED || product.state === InAppPurchase2.APPROVED) && product.transaction != null) {
    //     // alert('settings sending')
    //     const idToken = tokens.id_token;
    //     if (!idToken) {
    //       return false;
    //     }
    //     const payload = idToken.split('.')[1];
    //     const parsedToken: any = JSON.parse(decodeBase64(payload));
    //     const currentSub = parsedToken['custom:subscription'];
    //     const purchaseToken = parsedToken['custom:purchaseToken'];
    //     const isSameSubId = currentSub === product.id;
    //     if (isSameSubId && purchaseToken && JSON.parse(purchaseToken).purchaseToken === product.transaction.purchaseToken) {
    //       // alert('No need to call api again');
    //       return;
    //     }
    //     await authFetch(tokens, {
    //       url: 'sales/validation?page=settings',
    //       param: {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json; charset=UTF-8',
    //         },
    //         body: JSON.stringify(product),
    //       },
    //     });
    
    //     const tokenResult = await fetch(tokenUrl, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //       },
    //       body: `${refreshParameterBody}&refresh_token=${tokens.refresh_token}`,
    //     });
    //     if (tokenResult.status !== 200) {
    //       return;
    //     }
    //     const currentTokens = await tokenResult.json();
    //     // alert(JSON.stringify(currentTokens));
    //     saveTokens(currentTokens);
    //     setUserSubscription(IAPSubscriptions[product.id].title);
    //     AdMob.hideBanner();
    //     const el = document.getElementsByTagName("ion-app")[0];
    //     if (el) {
    //       (el as HTMLElement)['style'].marginTop = '0px';
    //     }
    //   }
    // });

    // InAppPurchase2.when(productId).approved((product: IAPProduct) => {
    //   // alert(`settings approved ${product.owned}, $${product.id}`);
    //   if (product.loaded && product.valid && product.state === InAppPurchase2.APPROVED && product.transaction != null) {
    //     // alert('settings finish');
    //     // product.verify();
    //     product.finish();
    //     setSuccessMessage('');
    //   }
    // });
  }
  if (true) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Subscriptions</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardContent>Coming Soon!</IonCardContent>
          </IonCard>
          
        </IonContent>
      </IonPage>
    )
  }
  return (
    <IonPage>
      <IonLoading
        isOpen={loading}
      />
      <IonToast onDidDismiss={() => setSuccessMessage('')} isOpen={!!successMessage} message={successMessage} />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form noValidate onSubmit={buySubscription}>
          <IonList>
            <IonItemGroup>
              <IonCard>
                <IonCardContent>
                  <IonLabel position="stacked" color="dark"><b>Username:</b> {username}</IonLabel>
                  <IonLabel position="stacked" color="dark"><b>Your Subscription:</b> {userSubscription}</IonLabel>
                </IonCardContent>
              </IonCard>
            </IonItemGroup>
            <IonItemDivider>
              <h5>Select a subscription</h5>
            </IonItemDivider>
            <IonItemGroup>
            {/* { Object.keys(subscriptionList).length &&
                Object.keys(subscriptionList).map((item: any, index: number) => {
                  const list = subscriptionList[item];
                  return (
                    <IonItem className={subscription === item ? 'selected' : ''} button key={index} onClick={() => {
                      setSubscription(list.id);
                      setSubscriptionError(false);
                    }}>
                      <IonLabel className="ion-text-wrap">
                        <b>{list.title}</b><IonChip color="dark">{list.price}</IonChip>
                        <p>{list.description}</p>
                      </IonLabel>
                    </IonItem>
                  )
                })
              } */}
              { JSON.parse(sub1) && JSON.parse(sub1).title &&
                <IonItem className={subscription === JSON.parse(sub1).id ? 'selected' : ''} button onClick={() => {
                  setSubscription(JSON.parse(sub1).id);
                  setSubscriptionError(false);
                }}>
                  <IonLabel className="ion-text-wrap">
                    <b>{JSON.parse(sub1).title}</b><IonChip color="dark">{JSON.parse(sub1).price}</IonChip>
                    <p>{JSON.parse(sub1).description}</p>
                  </IonLabel>
                </IonItem>
              }
              { JSON.parse(sub2) && JSON.parse(sub2).title &&
                <IonItem className={subscription === JSON.parse(sub2).id ? 'selected' : ''} button onClick={() => {
                  setSubscription(JSON.parse(sub2).id);
                  setSubscriptionError(false);
                }}>
                  <IonLabel className="ion-text-wrap">
                    <b>{JSON.parse(sub2).title}</b><IonChip color="dark">{JSON.parse(sub2).price}</IonChip>
                    <p>{JSON.parse(sub2).description}</p>
                  </IonLabel>
                </IonItem>
              }
            {formSubmitted && subscriptionError && <IonText color="danger">
              <p className="ion-padding-start">
                Subscription is required
              </p>
            </IonText>}
            </IonItemGroup>
            <br /><br />
            <IonItem>
              <IonLabel position="stacked" color="primary">Enter your password to continue</IonLabel>
              <IonInput name="password" type="password" value={password} onIonChange={e => {
                setPassword(e.detail.value!);
                setPasswordError(false);
              }}>
              </IonInput>
            </IonItem>

            {formSubmitted && passwordError && <IonText color="danger">
              <p className="ion-padding-start">
                Password is required
              </p>
            </IonText>}
            {formSubmitted && apiError && <IonText color="danger">
              <p className="ion-padding-start">
                {apiError}
              </p>
            </IonText>}
          </IonList>
          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">Buy</IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
          
    </IonPage>
  );
};

export default SubscriptionsPage;

