import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../store/user/slice';
import {
  IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
  IonButtons, IonMenuButton, IonRow, IonCol, IonButton,
  IonList, IonItem, IonLabel, IonInput, IonText, IonLoading, IonCard, IonCardContent,
} from '@ionic/react';
import { useHistory } from "react-router-dom";
import { RootState } from '../store'
import { forgotPassword } from '../configs';

const LoginPage: React.FC = () => {
  const loginError = useSelector((state: RootState) => state.user.loginError);
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    
    setFormSubmitted(true);
    if(!username) {
      setUsernameError(true);
    }
    if(!password) {
      setPasswordError(true);
    }
    if (!username || !password) {
      return;
    }
    setLoading(true);
    const result: any = await dispatch(login({ username, password }));
    setLoading(false);
    setUsername('');
    setPassword('');
    if (result.payload) {
      history.push('/timelines', { direction: 'forward' });
    }
  };

  return (
    <IonPage id="login-page">
      <IonLoading
        isOpen={loading}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <form noValidate onSubmit={submitLogin}>
              <IonList>
                <IonItem>
                  <IonLabel position="floating" color="primary">Username</IonLabel>
                  <IonInput name="username" type="text" value={username} spellCheck={false} autocapitalize="off" onIonChange={e => {
                    setUsername(e.detail.value!);
                    setUsernameError(false);
                  }}
                    required>
                  </IonInput>
                </IonItem>

                {formSubmitted && usernameError && <IonText color="danger">
                  <p className="ion-padding-start">
                    Username is required
                  </p>
                </IonText>}

                <IonItem>
                  <IonLabel position="floating" color="primary">Password</IonLabel>
                  <IonInput name="password" type="password" value={password} onIonChange={e => {
                    setPassword(e.detail.value!);
                    setPasswordError(false);
                  }}>
                  </IonInput>
                </IonItem>
                <p className="ion-padding-start">
                  <br />
                  <IonLabel position="floating" color="primary"><a href={forgotPassword}>Forgot your password?</a></IonLabel>
                </p>
                {formSubmitted && passwordError && <IonText color="danger">
                  <p className="ion-padding-start">
                    Password is required
                  </p>
                </IonText>}
                {formSubmitted && loginError && <IonText color="danger">
                  <p className="ion-padding-start">
                    {loginError}
                  </p>
                </IonText>}
              </IonList>

              <IonRow>
                <IonCol>
                  <IonButton type="submit" expand="block">Login</IonButton>
                  <p className="ion-text-center">
                    Don't have an account? <a onClick={() => {
                      history.push('/signup', {direction: 'none'});
                    }}>Sign Up</a>
                  </p>
                  
                </IonCol>
              </IonRow>
            </form>
          </IonCardContent>
        </IonCard>
      </IonContent>

    </IonPage>
  );
};

export default LoginPage;

