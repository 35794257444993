import { IonButton, IonHeader, IonTextarea, IonInput, IonTitle, IonIcon, IonButtons, IonContent, IonToolbar, IonItem, IonLabel, IonLoading } from "@ionic/react";
import { FormEvent, useState } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { closeOutline } from 'ionicons/icons';
import { Camera, CameraResultType } from '@capacitor/camera';
import { RootState } from '../store';
import { updateTimelineById, createTimeline } from '../store/timelines/slice';
interface ContainerProps {
  handleCancel: () => void;
  photo?: string;
  pTitle?: string;
  pDescription?: string;
  pTimelineId?: string;
}

const TimelineModal: React.FC<ContainerProps> = ({ pTitle, pDescription, pTimelineId, handleCancel, photo }) => {
  const tokens = useSelector((state: RootState) => state.user.tokens);
  const [image, setImage] = useState(photo);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [description, setDescription] = useState(pDescription);
  const [title, setTitle] = useState(pTitle);
  const takePicture = async () => {
    try {
      const image: any = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        correctOrientation: true,
        quality: 20,
        saveToGallery: true,
        allowEditing: false,
      });
      const imageUrl = image.webPath;
      setImage(imageUrl);
    } catch (e) {
      console.log(e);
    }
  };
  const submitTimeline = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const timelineId = e.target[0].value;
    const title = e.target[1].value;
    const description = e.target[2].value;
    const photoUrl = e.target[3].value;
    if (timelineId) {
      await dispatch(updateTimelineById({
        id: timelineId,
        title,
        description,
        url: photoUrl,
        newPhoto: image,
        tokens: tokens,
      }));
    } else {
      await dispatch(createTimeline({
        title,
        description,
        url: photoUrl,
        newPhoto: image,
        tokens: tokens,
      }));
    }
    
    handleCancel();
    setLoading(false);
  };
  return (
    <IonContent className="container">
      <IonLoading
        isOpen={loading}
      />
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">{ pTimelineId ? 'Edit' : 'New'} Timeline</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleCancel}><IonIcon icon={closeOutline} /></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <form className="ion-padding" onSubmit={e => { submitTimeline(e) }}>
        <IonItem>
          <IonInput value={pTimelineId} hidden />
          <IonLabel position="floating">Title</IonLabel>
          <IonInput type="text" value={title} onIonChange={(e) => setTitle((e.target as HTMLInputElement)!.value)} />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Description</IonLabel>
          <IonTextarea className="textarea" auto-grow={true} value={description} onIonChange={(e) => setDescription((e.target as HTMLInputElement)!.value)} />
          <IonInput value={photo} hidden />
        </IonItem>
        <img
          className="ion-float-left"
          alt=""
          src={image} />
          <br />
        {image && (
          <>
            <IonButton className="ion-float-left" color="light" onClick={() => setImage('')}>Remove Photo</IonButton>
          </>
        )}
        {!image && (
          <>
            <IonButton className="ion-float-left" onClick={takePicture}>Upload Photo</IonButton>
          </>
        )}
        <br/>
        <br/>
        <div>
          <IonButton className="ion-margin-top ion-float-right ion-float-bottom" type="submit">{ pTimelineId ? 'Save' : 'Add'}</IonButton>          <IonButton className="ion-margin-top ion-float-right" type="reset" color="light" onClick={handleCancel}>Cancel</IonButton>
        </div>
      </form>
    </IonContent>
  );
};

export default TimelineModal;
