import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import AboutPage from './pages/AboutPage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import TimelinesPage from './pages/TimelinesPage';
import ViewTimelinePageV2 from './pages/ViewTimelinePageV2';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import { RootState } from './store';
import { useSelector } from 'react-redux';
import { AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition } from '@capacitor-community/admob';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

AdMob.initialize({
  requestTrackingAuthorization: true,
  // testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
  initializeForTesting: true,
});
const androidBanner = 'ca-app-pub-2796376641761374/9487564205';
const interstitial = 'ca-app-pub-2796376641761374/8452301247';
const options: BannerAdOptions = {
  adId: androidBanner,
  // adId: 'ca-app-pub-3940256099942544/6300978111',
  adSize: BannerAdSize.BANNER,
  position: BannerAdPosition.BOTTOM_CENTER,
  margin: 0,
  // isTesting: true
  // npa: true
};
AdMob.showBanner(options);
const App: React.FC = () => {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  if (!isLoggedIn) {
    return (
      <IonApp>
      <IonReactRouter>

          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/login" />
            </Route>
            <Route path="/login" exact={true}>
              <LoginPage />
            </Route>
            
            <Route path="/signup" exact={true}>
              <SignUpPage />
            </Route>
   
          </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
    );
  }
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/timelines" />
            </Route>
            <Route path="/about" exact={true}>
              <AboutPage />
            </Route>
            <Route path="/subscriptions" exact={true}>
              <SubscriptionsPage />
            </Route>
            <Route path="/timelines" exact={true}>
              <TimelinesPage />
            </Route>
            <Route path="/timelines/:id" component={ViewTimelinePageV2} exact={true} />
            {/* <Route path="/timelines/:id">
              <ViewTimelinePageV2 />
            </Route> */}
            <Route path="/login" exact={true}>
              <LoginPage />
            </Route>
            <Route path="/signup" exact={true}>
              <SignUpPage />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
