import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import * as  AWSCognito from 'amazon-cognito-identity-js';
import { appClientId, userPoolId } from '../../configs';
import { reset as resetEvents }  from '../events/slice';
import { reset as resetTimelines }  from '../timelines/slice';

const asyncAuthenticateUser = (cognitoUser: any, cognitoAuthenticationDetails: any) => {
  return new Promise(function(resolve, reject) {
    cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
      newPasswordRequired: resolve
    })
  })
};

const asyncConfirmUser = (cognitoUser: any, confirmation: string) => {
  return new Promise(function(resolve, reject) {
    cognitoUser.confirmRegistration(confirmation, true, function(err: any, result: any) {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
    });
  });
}

const asyncSignUpUser = (userPool: AWSCognito.CognitoUserPool, email: string, password: string, attribute_list: any) => {
  return new Promise(function(resolve, reject) {
    userPool.signUp(email, password, attribute_list, [], (err: any, result: any) => {
      if (err) {
        console.log(err.message);
        reject(err);
        return;
      }
      cognitoUser = result.user;
      resolve(cognitoUser);
    });
  })
}

const poolData = {
  UserPoolId : userPoolId,
  ClientId : appClientId,
};
const userPool = new AWSCognito.CognitoUserPool(poolData);
let cognitoUser: AWSCognito.CognitoUser;
export const login = createAsyncThunk(
  'users/login',
  async (params: any, thunkAPI) => {
    const authDetails = new AWSCognito.AuthenticationDetails({
      Username: params.username,
      Password: params.password
    });
    cognitoUser = new AWSCognito.CognitoUser({
      Username: params.username,
      Pool: userPool
    });
    const result = await asyncAuthenticateUser(cognitoUser, authDetails);
    return result;
  }
)

export const signUp = createAsyncThunk(
  'users/signUp',
  async (params: any, thunkAPI) => {
    const { email, username, password } = params;
    const userAttribute = [
      new AWSCognito.CognitoUserAttribute({ Name: "email", Value: email }),
    ];
    const result = await asyncSignUpUser(userPool, username, password, userAttribute);
    console.log(result);
    return result;
  }
)

export const confirm = createAsyncThunk(
  'users/confirm',
  async (params: any, thunkAPI) => {
    const { username, confirmation } = params;
    const userData = {
      Username: username,
      Pool: userPool,
    };
    cognitoUser = new AWSCognito.CognitoUser(userData);
    const result = await asyncConfirmUser(cognitoUser, confirmation);
    return result;
  }
)

export const logout = createAsyncThunk(
  'users/logout',
  async (params: any, thunkAPI) => {
    const result = await cognitoUser.signOut();
    await thunkAPI.dispatch(resetEvents());
    await thunkAPI.dispatch(resetTimelines());
    return result;
  }
)

export const getAttr = createAsyncThunk(
  'users/getAttr',
  async (thunkAPI) => {
    cognitoUser.getUserAttributes(function(err, result: any) {
      if (err) {
        console.log(err);
        return;
      }
      console.log(result);
    });
  }
)

interface UserState {
  value: number;
  tokens: any;
  username: string;
  isLoggedIn: boolean;
  loginError: any;
  signUpError: any;
  showConfirmation: boolean;
};

const initialState = { value: 0 } as UserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveTokens(state, action: PayloadAction<any>) {
      state.tokens = action.payload;
      state.isLoggedIn = true;
    },
    increment(state) {
      state.value++
    },
    decrement(state) {
      state.value--
    },
    incrementByAmount(state, action: PayloadAction<number>) {
      state.value += action.payload
    },
    updateToken(state, action: PayloadAction<any>) {
      state.tokens = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
          state.isLoggedIn = false;
          state.loginError = null;
          state.tokens = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.tokens = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loginError = action.error.message;
      })
      .addCase(logout.pending, (state, action) => {
        
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.tokens = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.tokens = null;
        console.log('logout failed');
      })
      .addCase(signUp.pending, (state, action) => {
        state.showConfirmation = false;
        state.username = action.meta.arg.username;
        state.signUpError = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.showConfirmation = true;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.signUpError = action.error.message;
      })
      .addCase(confirm.pending, (state, action) => {
        state.username = action.meta.arg.username;
        state.signUpError = null;
      })
      .addCase(confirm.fulfilled, (state, action) => {
        state.showConfirmation = false;
      })
      .addCase(confirm.rejected, (state, action) => {
        state.signUpError = action.error.message;
      })
  },
})

export const { increment, decrement, incrementByAmount, saveTokens, updateToken } = userSlice.actions
export default userSlice.reducer