import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { apiUrl } from '../../configs';
import { authFetch } from '../../utils';

export const getAllTimelines = createAsyncThunk(
  'timelines/getAll',
  async (params: any, thunkAPI) => {
    const { tokens } = params;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/all`,
    }, thunkAPI)
    // const result = await fetch(`${apiUrl}/timelines/all`, {
    //   headers: {
    //     Authorization: `Bearer ${idToken}`,
    //   },
    // });
    const { status } = result;
    const body = await result.json();
    if (status === 200) {
      return thunkAPI.fulfillWithValue(body);
    }
    return thunkAPI.rejectWithValue(body);
  }
)

export const createTimeline = createAsyncThunk(
  'timelines/create',
  async (params: any, thunkAPI) => {
    const { newPhoto, tokens, title, description } = params;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/`,
      param: {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title, description, hasPhoto: !!newPhoto,
        }),
      },
    }, thunkAPI);
    const { status } = result;
    const body = await result.json();
    if (status !== 201) {
      return thunkAPI.rejectWithValue(body);  
    }
    if (newPhoto) { 
      const { uploadUrl } = body;  
      const uploadResult = await fetch(uploadUrl, {
        method: 'PUT',
          headers: {
            'Content-Type': 'image/jpg'
          },
          body: await fetch(newPhoto).then(r => r.blob()),
      });
      console.log('uploadresult', uploadResult);
    }
    return thunkAPI.fulfillWithValue(body);
  }
)

export const updateTimelineById = createAsyncThunk(
  'timelines/updateTimelineById',
  async (params: any, thunkAPI) => {
    const { newPhoto, tokens, id, title, url, description } = params;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/${id}`,
      param: {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title, description, url, newPhoto,
        }),
      },
    }, thunkAPI);
    const { status } = result;
    const body = await result.json();
    if (status === 200) {
      if (newPhoto) {
        const { uploadUrl } = body;
        const uploadResult = await fetch(uploadUrl, {
          method: 'PUT',
            headers: {
              'Content-Type': 'image/jpg'
            },
            body: await fetch(newPhoto).then(r => r.blob()),
        });
        console.log(uploadResult);
      }
      return thunkAPI.fulfillWithValue(body);
    }
    return thunkAPI.rejectWithValue(body);
  }
)

export const deleteTimelineById = createAsyncThunk(
  'timelines/deleteTimelineById',
  async (params: any, thunkAPI) => {
    const { tokens, id } = params;
    const result = await authFetch(tokens, {
      url: `${apiUrl}/timelines/${id}`,
      param: {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    }, thunkAPI);
    const { status } = result;
    const body = await result.json();
    console.log(body);
    if (status === 200) {
      return thunkAPI.fulfillWithValue(id);
    }
    return thunkAPI.rejectWithValue(body);
  }
)
interface TimelinesState {
  timelines: any;
  nextKey: any,
  selectedId: string;
  loading: boolean;
  apiError: any;
};

const initialState = { timelines: [] } as TimelinesState

const timelinesSlice = createSlice({
  name: 'timelines',
  initialState,
  reducers: {
    reset(state) {
      state.timelines = [];
    },
    resetApiError(state) {
      state.apiError = '';
    },
    decrement(state) {
      state.loading = false;
    },
    incrementByAmount(state, action: PayloadAction) {
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTimelines.pending, (state, action) => {
         state.loading = true;
         state.apiError = '';
      })
      .addCase(getAllTimelines.fulfilled, (state, action: any) => {
        state.loading = false;
        state.timelines = action.payload.data;
        state.nextKey = action.payload.nextKey;        
      })
      .addCase(getAllTimelines.rejected, (state, action) => {
        state.loading = false;
        state.apiError = action.payload;
      })
      .addCase(createTimeline.pending, (state, action) => {
        state.loading = true;
        state.apiError = '';
      })
      .addCase(createTimeline.fulfilled, (state, action: any) => {
        state.loading = false;
        console.log(action.payload)
        // state.timelines = action.payload.data;
        const newTimelines = JSON.parse(JSON.stringify(state.timelines))
        newTimelines.unshift(action.payload);
        state.timelines = newTimelines;
      })
      .addCase(createTimeline.rejected, (state, action: any) => {
        state.loading = false;
        state.apiError = action.payload.error.description;
      })
      .addCase(updateTimelineById.pending, (state, action) => {
        state.loading = true;
        state.apiError = '';
      })
      .addCase(updateTimelineById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.timelines = state.timelines.map((item: any) => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        });
      })
      .addCase(updateTimelineById.rejected, (state, action) => {
        state.loading = false;
        state.apiError = action.payload;
      })
      .addCase(deleteTimelineById.pending, (state, action) => {
        state.loading = true;
        state.apiError = '';
      })
      .addCase(deleteTimelineById.fulfilled, (state, action: any) => {
        state.loading = false;
        const newTimelines = JSON.parse(JSON.stringify(state.timelines)).filter((item: any) => {
          if (item.id === action.payload) {
            return false;
          }
          return true;
        });
        state.timelines = newTimelines;
      })
      .addCase(deleteTimelineById.rejected, (state, action) => {
        state.loading = false;
        state.apiError = action.payload;
      })
  },
})

export const { reset, decrement, incrementByAmount, resetApiError } = timelinesSlice.actions
export default timelinesSlice.reducer