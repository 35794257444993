import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { signUp, confirm } from '../store/user/slice';
import {
  IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
  IonButtons, IonMenuButton, IonRow, IonCol, IonButton,
  IonCard, IonCardContent,
  IonList, IonItem, IonLabel, IonInput, IonText, IonLoading, IonToast,
} from '@ionic/react';
import { useHistory } from "react-router-dom";
import { RootState } from '../store';

const SignUpPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.value);
  const signUpError = useSelector((state: RootState) => state.user.signUpError);
  const showConfirmation = useSelector((state: RootState) => state.user.showConfirmation);
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState('');
  const [showToast, setShowToast] = useState(false);

  const signup = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if(!username) {
      setUsernameError(true);
    }
    if(!email) {
      setEmailError(true);
    }
    if(!password) {
      setPasswordError(true);
    }
    if (!username || !password || !email) {
      return;
    }
    setLoading(true);
    const result = await dispatch(signUp({ username, email, password }));
    setLoading(false);
    console.log('done2-----', result);
  };

  const submitConfirm = async (e: React.FormEvent) => {
    e.preventDefault();
    setConfirmationError('');
    setFormSubmitted(true);
    if(!confirmation) {
      setConfirmationError('');
      return;
    }
    setLoading(true);
    
    const result: any = await dispatch(confirm({ confirmation, username }));
    setLoading(false);
    if (result.payload === 'SUCCESS') {
      setShowToast(true);
      history.push('/login', {direction: 'none'});
    }
  };
  return (
    <IonPage id="login-page">
      <IonLoading
        isOpen={loading}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Successfully signed up. Please login"
        duration={1500}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Signup</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            {!showConfirmation &&
              <form noValidate onSubmit={signup}>
                <IonList>
                  <IonItem>
                    <IonLabel position="floating" color="primary">Username</IonLabel>
                    <IonInput name="username" type="text" value={username} spellCheck={false} autocapitalize="off" onIonChange={e => {
                      setUsername(e.detail.value!);
                      setUsernameError(false);
                    }}
                      required>
                    </IonInput>
                  </IonItem>

                  {formSubmitted && usernameError && <IonText color="danger">
                    <p className="ion-padding-start">
                      Username is required
                    </p>
                  </IonText>}
                  
                  <IonItem>
                    <IonLabel position="floating" color="primary">Email</IonLabel>
                    <IonInput name="email" type="text" value={email} spellCheck={false} autocapitalize="off" onIonChange={e => {
                      setEmail(e.detail.value!);
                      setEmailError(false);
                    }}
                      required>
                    </IonInput>
                  </IonItem>

                  {formSubmitted && emailError && <IonText color="danger">
                    <p className="ion-padding-start">
                      Email is required
                    </p>
                  </IonText>}
                  
                  <IonItem>
                    <IonLabel position="floating" color="primary">Password</IonLabel>
                    <IonInput name="password" type="password" value={password} onIonChange={e => {
                      setPassword(e.detail.value!);
                      setPasswordError(false);
                    }}>
                    </IonInput>
                  </IonItem>

                  {formSubmitted && passwordError && <IonText color="danger">
                    <p className="ion-padding-start">
                      Password is required
                    </p>
                  </IonText>}
                  {formSubmitted && signUpError && <IonText color="danger">
                    <p className="ion-padding-start">
                      {signUpError}
                    </p>
                  </IonText>}
                </IonList>
                <IonRow>
                  <IonCol>
                    <IonButton type="submit" expand="block">Signup</IonButton>
                    <p className="ion-text-center">
                      Already have an account? <a onClick={() => {
                        history.push('/login', {direction: 'none'});
                      }}>Login</a>
                    </p>
                  </IonCol>
                </IonRow>
              </form>
              }
              { showConfirmation &&
              <form noValidate onSubmit={submitConfirm}>
                <IonItem>
                  <p>Confirmation Code is sent to your email.</p>
                  <IonLabel position="floating" color="primary">Confirmation Code</IonLabel>
                  <IonInput name="confirmation" type="text" value={confirmation} onIonChange={e => {
                    setConfirmation(e.detail.value!);
                    setConfirmationError('');
                  }}>
                  </IonInput>
                </IonItem>

                {formSubmitted && confirmationError && <IonText color="danger">
                  <p className="ion-padding-start">
                    Confirmation Code is required
                  </p>
                </IonText>}
                {formSubmitted && signUpError && <IonText color="danger">
                    <p className="ion-padding-start">
                      {signUpError}
                    </p>
                  </IonText>}
                <IonRow>
                  <IonCol>
                    <IonButton type="submit" expand="block">Confirm</IonButton>
                  </IonCol>
                </IonRow>
              </form>
            }
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default SignUpPage;

