import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useParams } from 'react-router';
import { useIonAlert, IonButtons, IonIcon, IonContent, IonFab, IonFabButton, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, IonCard, IonCardContent, IonItem, IonLabel, IonImg, IonModal, IonLoading, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import {  add, createOutline, trashBinOutline  } from 'ionicons/icons';
import EventModal from '../components/EventModal';
import TimelineModal from '../components/TimelineModal';
import './Page.css';
import { RootState } from '../store';
import { deleteTimelineById } from '../store/timelines/slice';
import { getAllEvents, getAllEventsNext } from '../store/events/slice';
import { useHistory } from "react-router-dom";
import EventItem from '../components/EventItem';
import { AdMob, AdOptions } from '@capacitor-community/admob';
interface ParamTypes {
  id: string;
}
interface Event {
  id?: string;
  title?: string;
  description?: string;
  datetime?: any;
  timelineId: string;
  updatedOn?: number;
  url?: string;
}

const ViewTimelinePage: React.FC = () => {
  const [present] = useIonAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedEvent, setSelectedEvent] = useState<any>({});
  const tokens = useSelector((state: RootState) => state.user.tokens);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<ParamTypes>();
  const apiLoading = useSelector((state: RootState) => state.events.loading);
  const thisTimeline = useSelector((state: RootState) => {
    const currentTimeline = state.timelines.timelines.filter((item: any) => item.id === id);
    return currentTimeline[0] || {};
  });
  const nextKey = useSelector((state: RootState) => {
    const nextKey = state.events.events[thisTimeline.id] && state.events.events[thisTimeline.id].nextKey;
    return nextKey || '';
  });
  const events = useSelector((state: RootState) => {
    const allEvents = state.events.events[thisTimeline.id];
    // console.log(state.events, thisTimeline.id)
    if (allEvents) { 
      return allEvents.data;
    }
    return [];
  });
  
  useEffect(() => {
    showInterstitial();
    dispatch(getAllEvents({ tokens, timelineId: thisTimeline.id }));
  }, [dispatch, id]);
  
  const [showTimelineModal, setShowTimelineModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const closeTimelineModal = () => {
    setShowTimelineModal(false);
  };
  const closeEventModal = () => {
    setSelectedEvent({});
    setShowEventModal(false);
  };
  const editEvent = (id: string) => {
    const thisEvent = events.find((item: Event) => {
      return item.id === id
    });
    setSelectedEvent(thisEvent);
    setShowEventModal(true);
  };
  const deleteHandler = async () => {
    setLoading(true);
    history.push('/timelines', {direction: 'none'});
    await dispatch(deleteTimelineById({ tokens, id: thisTimeline.id }));
    setLoading(false);
  }
  const interstitial = 'ca-app-pub-2796376641761374/8452301247';
  const options: AdOptions = {
    adId: interstitial,
    // adId: 'ca-app-pub-3940256099942544/1033173712',
    // isTesting: true
    // npa: true
  };
  const showInterstitial = async () => {
    await AdMob.prepareInterstitial(options);
    await AdMob.showInterstitial();
  };
  
  const loadMore = (event: CustomEvent<void>) => {
    dispatch(getAllEventsNext({ tokens, timelineId: thisTimeline.id, nextKey }));
    event && (event.target as any).complete();
  };
  return (
    <IonPage>
      <IonLoading
        isOpen={apiLoading || loading}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{thisTimeline.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton size="large" onClick={() => setShowTimelineModal(true)}>
              <IonIcon icon={createOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonModal isOpen={showEventModal} >
        <EventModal
          photo={selectedEvent.url}
          pDatetime={selectedEvent.datetime}
          timelineId={id}
          eventId={selectedEvent.id}
          pTitle={selectedEvent.title}
          pDescription={selectedEvent.description}
          tokens={tokens}
          handleCancel={closeEventModal}
          />
      </IonModal>
      <IonModal isOpen={showTimelineModal}>
        <TimelineModal
          handleCancel={closeTimelineModal}
          photo={thisTimeline.url}
          pTitle={thisTimeline.title}
          pDescription={thisTimeline.description}
          pTimelineId={thisTimeline.id}
          />
      </IonModal>
      <IonContent fullscreen>
        <IonFab slot="fixed" horizontal="end" vertical="bottom" >
          <IonFabButton color="new" onClick={() => setShowEventModal(true)}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
        <IonFab slot="fixed" horizontal="start" vertical="bottom" >
          <IonFabButton color="danger"
             onClick={() =>
              present({
                cssClass: 'my-css',
                header: 'Delete',
                message: 'Are you sure you want to delete this timeline?',
                buttons: [
                  'Cancel',
                  {
                    text: 'Ok',
                    handler: deleteHandler,
                  },
                ],
                onDidDismiss: (e) => console.log('did dismiss'),
              })
            }
          >
            <IonIcon icon={trashBinOutline} />
          </IonFabButton>
        </IonFab>
        {
          events.length === 0 && (
            <IonCardContent>
              <p>You have no events on this timeline.</p>
              <IonButton onClick={() => setShowEventModal(true)}>Click here to add events.</IonButton>
            </IonCardContent>
          )
        }
        {
          events.length > 0 && (
            <div className="timeline-wrapper">
              <ul className="timeline">
                {
                  events.map((item: any, index: number) => {
                    return <EventItem key={index} handleEdit={editEvent} event={item} />
                  })
                }
              </ul>
            </div>
          )
        }
        <IonInfiniteScroll
          disabled={!!!nextKey}
          threshold="100px" onIonInfinite={loadMore}>
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..." />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default ViewTimelinePage;
