import { refreshParameterBody, tokenUrl } from '../configs';
import { updateToken } from '../store/user/slice';

export const authFetch = async (tokens: any, params: any, thunkAPI?: any) => {
  const todayTime = new Date().getTime();
  const { idToken, refreshToken }= tokens;
  const { exp } = idToken.payload;
  const { token } = refreshToken;
  let body = tokens;
  let { jwtToken } = idToken;
  if (exp * 1000 < todayTime) {
    const result = await fetch(tokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `${refreshParameterBody}&refresh_token=${token}`,
    });
    body = await result.json();
    jwtToken = body.id_token;
    const newTokens = JSON.parse(JSON.stringify(tokens));
    newTokens.accessToken.jwtToken = body.access_token;
    newTokens.idToken.jwtToken = jwtToken;
    newTokens.idToken.payload.exp = (todayTime / 1000) + body.expires_in;
    await thunkAPI.dispatch(updateToken(newTokens));
  }
  const result2 = await fetch(params.url, {
    ...params.param,
    headers: {
      ...(params.param && params.param.headers ? params.param.headers : {}),
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  return result2;
};


