import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle
} from '@ionic/react';

const version = 'v1.1.0';
const releasedDate = '8/15/2021';
const versionCode = 5;

const AboutPage: React.FC = () => {
  return (
    <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="timelines" />
                </IonButtons>
                <IonTitle>About</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent>
            <div className="about-header">
                <img style={{"height": "200px"}} src="assets/icon/icon2.jpg" alt="Parklers"/>
            </div>
            <div className="ion-padding about-info">
                <h4>Personal Timeline</h4>
                <br />
                <p>
                    This app will help you create your own personal timeline. Store all the memories you have by date and time. You can also include photos on the timeline.
                    <br/> <br/>
                    Please rate and comment.  Any feedback will be great.
                    <br /><br />
                    {/* Donations are also welcomed to help me keep the service up. */}
                </p>
                {/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="M8MKDUXW862GQ" />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form> */}
                
                <br/>
                <br />
                <div>
                    <b>Version:</b> v{version}({versionCode})<br />
                    <b>Released Date:</b> {releasedDate}<br />
                </div>
            </div>
        </IonContent>
    </IonPage>
  );
};

export default AboutPage;
