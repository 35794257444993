import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';
import { logout } from '../store/user/slice';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { heartOutline, heartSharp, homeOutline, homeSharp, logIn, logInSharp, logOut, logOutSharp, paperPlaneOutline, paperPlaneSharp  } from 'ionicons/icons';
import './Menu.css';
import { RootState } from '../store';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const loginPage: AppPage = {
  title: 'Login',
  url: '/login',
  iosIcon: logIn,
  mdIcon: logInSharp
};
const logoutPage: AppPage = {
  title: 'Logout',
  url: '/login',
  iosIcon: logOut,
  mdIcon: logOutSharp,
};
const appPages: AppPage[] = [
  {
    title: 'Timelines',
    url: '/timelines',
    iosIcon: homeOutline,
    mdIcon: homeSharp
  },
  {
    title: 'Subscriptions',
    url: '/subscriptions',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp
  },
  {
    title: 'About',
    url: '/about',
    iosIcon: heartOutline ,
    mdIcon: heartSharp
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Your Timeline</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          {
            (
              <IonMenuToggle autoHide={false}>
                <IonItem
                  onClick={() => {
                    if(isLoggedIn) {
                      dispatch(logout({}));
                    }
                  }}
                  className={location.pathname === loginPage.url ? 'selected' : ''} routerLink={loginPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={isLoggedIn ? logoutPage.iosIcon : loginPage.iosIcon} md={isLoggedIn ? logoutPage.mdIcon : loginPage.mdIcon} />
                  <IonLabel>{isLoggedIn ? logoutPage.title : loginPage.title }</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )
          }
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
